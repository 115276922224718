export default {
    methods:
    {
        //Amount Coma Separate

        numberWithCommas(x) {
           /*  if(x)
            {
                return x.toString().split('.')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
            }
            else
            {
                return 0;
            } */
            var num = x ? x : 0;
				return num.toLocaleString('en-IN')
        },
        dateTime() {
            var ndate = new Date();
            var hours = ndate.getHours();
            var message = hours < 12 ? 'Good Morning' : hours < 18 ? 'Good Afternoon' : 'Good Evening';
            return message;
            
        },
      
        startTimer() {
            const blockedTime = new Date().getTime();
            const blockTime = new Date(this.countDownToTime).getTime();
            const timeDifference = blockTime - blockedTime;
            const millisecondsInOneSecond = 1000;
            const millisecondsInOneMinute = millisecondsInOneSecond * 60;
            const millisecondsInOneHour = millisecondsInOneMinute * 60;
            const millisecondsInOneDay = millisecondsInOneHour * 24;
            const remainderDifferenceInHours = (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
            const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
            const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
            const remainingHours = Math.floor(remainderDifferenceInHours);
            const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
            const remainingSeconds =Math.floor(remainderDifferenceInSeconds);
            this.timerCount=remainingHours +":"+ remainingMinutes +":"+ remainingSeconds;
        },
        sumofPercentage(count,total)
        {
            if(total)
            {
                return (count/total*100).toFixed(2);
            }
            else{
                return 0;
            }
        },
        date_format(date)
        {
            var d1 = new Date(date);
            var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();
            
        },
        getDateOrdinal(date) {
            let day = date.getDate();
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1:  return "st";
                case 2:  return "nd";
                case 3:  return "rd";
                default: return "th";
            }
        },
        goBack() {
            this.$router.go(-1)
          },
          convert_24_12hour(timeString)
          {
            const [hourString, minute] = timeString.split(":");
            const hour = +hourString % 24;
            return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
          },
        
    }
   
}